/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog } from "@progress/kendo-react-dialogs";
import "../../styles/campaigns/previewLeads.css";
import "../../styles/shared/loadingPage.css";
import { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { previewLeadsListColumn } from "./gridColumns";
import { Pager } from "@progress/kendo-react-data-tools";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { useNavigate } from "react-router-dom";
import fetchClient from "../../api/fetch";
import { Loader } from "@progress/kendo-react-indicators";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { checkMarketAu, formatCurrency } from "../../utils/helpers";
import InterestRateModalConfirmation from "./InterestRateModalConfirmation";

const PreviewLeadsModal = ({
  setShowPreviewLeads,
  setShowCampaignNameExistError,
  setSuccessModalValues,
  leadsReferenceKey,
  getLeadsList,
  leadsList,
  leadsListPage,
  marketSettings,
  uncheckedIds,
  payload,
  payloadHolder,
  mode,
  id
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const columns = previewLeadsListColumn;
  const currencyOptions = {
    symbol: marketSettings.currencySymbol
  };

  const [page, setPage] = useState({
    skip: 0,
    take: 10
  });
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = useState(undefined);
  const [params, setParams] = useState({
    sort: "",
    filter: []
  });
  const [leadsCount, setLeadsCount] = useState(undefined);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // unchecked leads
  const [unchecked, setUnchecked] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [event, setEvent] = useState();

  useEffect(() => {
    getLeadsList(queryBuilder());
  }, [page, params]);

  //remove
  useEffect(() => {
    console.log(payload);
  }, [payload]);

  const queryBuilder = () => {
    const refKey = `referenceKey=${leadsReferenceKey}`;
    return `?${refKey}${params.sort}&page=${JSON.stringify(page)}&filters=[${
      params.filter
    }]`;
  };

  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...e.page,
      skip: e.skip,
      take
    });
  };

  const handleSort = (e) => {
    let sortVal = e.sort;
    setSort(sortVal);
    if (sortVal.length > 0) {
      sortVal = sortVal.map((s) => ({
        dir: s.dir,
        field: s.field.charAt(0).toUpperCase() + s.field.substr(1)
      }));
      let obj = { ...sortVal[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setParams({ ...params, sort: `&sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.dataState.filter);
    if (e.dataState.filter !== undefined) {
      setParams({
        ...params,
        filter: e.dataState.filter.filters.map((item) => {
          let obj = { ...item };
          obj["condition"] = obj["operator"];
          delete obj["operator"];
          obj = JSON.stringify(obj);
          return obj;
        })
      });
      setPage({
        ...page,
        skip: 0
      });
    } else {
      setParams({ ...params, filter: "" });
    }
  };

  const handleChange = (e) => {
    const leadsValue = e.target.value;
    if (!leadsValue || /^[1-9]\d*$/.test(leadsValue)) {
      setLeadsCount(leadsValue);
    } else {
      setLeadsCount(undefined);
    }
  };

  const sendData = (e) => {
    setOpenModal(false);
    setIsLoading(true);
    const ivids = e === "save" ? selectedLeads : [];
    const data = {
      ...payload,
      ivIds: ivids,
      replacementManufacturerDeposit: payload.replacementManufacturerDeposit ? payload.replacementManufacturerDeposit : null,
      replacementDealerDeposit: payload.replacementDealerDeposit ? payload.replacementDealerDeposit : null,
      replacementMonthlyPaymentDifference:  payload.replacementMonthlyPaymentDifference ? payload.replacementMonthlyPaymentDifference : null,
      // replacementInterestRate: payload.replacementInterestRate / 100,
      saveUpTo: e === "saveUpTo" ? leadsCount : null,
      referenceKey: leadsReferenceKey
    };

    let isSelectedReplacementId = payload.campaignReplacementVehicles
      ? payload.campaignReplacementVehicles?.length > 0
      : false;
    let isSelectedIvId = payload.campaignExistingVehicles.length > 0;
    let selectedParams = `&isSelectedReplacementId=${isSelectedReplacementId}&isSelectedIvId=${isSelectedIvId}`;
    if (uncheckedIds.replacementVS?.length > 0) {
      selectedParams += `&replacementVehicleId=${uncheckedIds.replacementVS.toString()}`;
    }
    if (uncheckedIds.existingVS?.length > 0) {
      selectedParams += `&unselectedIvId=${uncheckedIds.existingVS.toString()}`;
    }

    if (mode === "create") {
      fetchClient()
        .post(
          `v1/campaigns/leads?${params.sort}&filters=[${params.filter}]` +
            selectedParams,
          data
        )
        .then(() => {
          setIsLoading(false);
          setShowPreviewLeads(false);
          setSuccessModalValues({
            message: "Campaign saved successfully",
            isVisible: true,
            closeCallBack: handleCloseModal
          });
        })
        .catch(() => {
          setShowPreviewLeads(false);
          setShowCampaignNameExistError(true);
        });
    } else {
      fetchClient()
        .put(
          `v1/campaigns/${id}/leads?${params.sort}&filters=[${params.filter}]` +
            selectedParams,
          data
        )
        .then(() => {
          setIsLoading(false);
          setShowPreviewLeads(false);
          setSuccessModalValues({
            message: "Campaign saved successfully",
            isVisible: true,
            closeCallBack: handleCloseModal
          });
        })
        .catch(() => {
          setShowPreviewLeads(false);
          setShowCampaignNameExistError(true);
        });
    }
  };

  const handleSave = (e) => {
    setEvent(e);
    if (
      Number(payload.replacementInterestRate * 100).toFixed(2) ===
        marketSettings.defaultInterestRateFormatted.toFixed(2) ||
      Number(payloadHolder.current.replacementInterestRate * 100).toFixed(2) ===
        Number(payload.replacementInterestRate * 100).toFixed(2)
    ) {
      sendData(e);
    } else {
      setOpenModal(true);
    }
  };

  const handleSaveClick = () => {
    sendData(event);
  };

  const handleCancelSave = () => {
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    Navigate("/campaigns");
  };

  const handleSelectAll = () => {
    if (selectedLeads.length || unchecked.length) {
      setSelectedLeads([]);
    } else {
      setSelectedLeads([""]);
    }
    setUnchecked([]);
  };

  const handleSelect = (dataItem) => {
    const { ivId } = dataItem;
    if (selectedLeads.length === 0) {
      if (unchecked.includes(ivId)) {
        setUnchecked(unchecked.filter((lead) => lead !== ivId));
      } else {
        setUnchecked(unchecked.concat(ivId));
      }
    } else {
      if (selectedLeads.includes(ivId)) {
        const holderArr = selectedLeads.filter((lead) => lead != ivId);

        setSelectedLeads(holderArr.length > 0 ? holderArr : [""]);
      } else {
        setSelectedLeads(
          selectedLeads.includes("") ? [ivId] : selectedLeads.concat(ivId)
        );
      }
    }
  };

  const customPager = (props) => {
    return (
      <div className="custom-pager">
        <Pager
          skip={props.skip}
          take={props.take}
          type="numeric"
          total={props.total}
          buttonCount={props.buttonCount}
          previousNext={true}
          pageSizes={props.pageSizes}
          pageSizeValue={props.pageSizeValue}
          onPageChange={pageChange}
        />
      </div>
    );
  };

  const actionsCell = ({ dataItem }) => {
    return (
      <td className="text-center">
        <Checkbox
          value={
            !selectedLeads.length
              ? !unchecked.includes(dataItem.ivId)
              : selectedLeads.includes(dataItem.ivId)
          }
          onChange={() => handleSelect(dataItem)}
        />
      </td>
    );
  };

  const equityCell = ({ dataItem }) => {
    const equityValue = dataItem.equityFormatted;
    const isNegative = equityValue?.toString()?.includes("-");

    return (
      <td
        colSpan={1}
        role="gridCell"
        className={`k-table-td ${isNegative ? "negative-equity" : "equity"}`}
      >
        {equityValue
          ? formatCurrency(equityValue, currencyOptions.symbol)
          : null}
      </td>
    );
  };

  const currencyCell = (props) => {
    const { dataItem, field } = props;
    const value = dataItem[field];

    return (
      <td colSpan={1} role="gridCell" className="k-table-td">
        {value ? formatCurrency(value, currencyOptions.symbol) : null}
      </td>
    );
  };

  const licensePlateCell = (props) => {
    const { dataItem, field } = props;
    const licensePlate = dataItem[field];

    return (
      <td role="gridCell" className="k-table-td">
        <div>
          {checkMarketAu() ? <span>-</span> : <span>{licensePlate}</span>}
        </div>
      </td>
    );
  };

  const actionsHeaderCell = ({ dataItem }) => {
    return (
      <span className="k-cell-inner">
        <span className="k-link" onClick={handleSelectAll}>
          <span>
            <Checkbox value={!selectedLeads.length && !unchecked.length} />
          </span>
          <span className="k-column-title select-all">
            {getTranslation("Select all", t)}
          </span>
        </span>
      </span>
    );
  };
  
  return (
    <Dialog
      className="preview-leads"
      onClose={() => setShowPreviewLeads(false)}
    >
      {isLoading ? (
        <div className="loading grid-light-background">
          <Loader type="converging-spinner" />
        </div>
      ) : null}

      <div className="d-flex flex-column gap-3 p-2">
        <h1 className="h1 mb-3">{getTranslation("Preview Leads", t)}</h1>
        <div className="modal-cont">
          <div className="grid-cont">
            <Grid
              className="grid-view"
              data={leadsList}
              skip={page.skip}
              take={page.take}
              total={leadsListPage.totalRecords}
              filterable={true}
              filter={filter}
              sortable={true}
              sort={sort}
              onSortChange={handleSort}
              onDataStateChange={handleFilterChange}
              pager={customPager}
              pageable={{
                buttonCount:
                  leadsListPage.totalPages > 10 ? 10 : leadsListPage.totalPages,
                pageSizes: [5, 10, 15],
                pageSizeValue
              }}
            >
              {columns.map((column, idx) => {
                if (idx === 0) {
                  return (
                    <Column
                      key={idx}
                      width={column.width}
                      cell={actionsCell}
                      headerCell={actionsHeaderCell}
                      filterable={false}
                      title={getTranslation(column.title, t)}
                    />
                  );
                } else if (
                  getTranslation(column.title, t) ===
                  getTranslation("Equity", t)
                ) {
                  return (
                    <Column
                      key={idx}
                      field={column.column}
                      width={column.width}
                      cell={equityCell}
                      title={getTranslation(column.title, t)}
                      filterable={true}
                    />
                  );
                } else if (column.title === "Source") {
                  return (
                    <Column
                      key={idx}
                      field={column.column}
                      width={column.width}
                      title={getTranslation(column.title, t)}
                      filterable={false}
                    />
                  );
                } else if (
                  [
                    getTranslation("Cost to change", t),
                    getTranslation("New Payment", t),
                    getTranslation("Payment", t),
                    getTranslation("Payment Difference", t)
                  ].includes(getTranslation(column.title, t))
                ) {
                  return (
                    <Column
                      key={idx}
                      field={column.column}
                      width={column.width}
                      cell={currencyCell}
                      title={getTranslation(column.title, t)}
                      filterable={true}
                    />
                  );
                } else if (
                  [getTranslation("Customer", t)].includes(
                    getTranslation(column.title, t)
                  )
                ) {
                  return column.isShown && !checkMarketAu() ? (
                    <Column
                      key={idx}
                      field={column.column}
                      width={column.width}
                      title={getTranslation(column.title, t)}
                      sortable={
                        column.sortable ||
                        getTranslation(column.title, t) !==
                          getTranslation("Source", t)
                      }
                      filterable={true}
                    />
                  ) : null;
                } else if (
                  [getTranslation("License Plate", t)].includes(
                    getTranslation(column.title, t)
                  )
                ) {
                  return (
                    <Column
                      key={idx}
                      field={column.column}
                      width={column.width}
                      cell={licensePlateCell}
                      title={getTranslation(column.title, t)}
                      sortable={
                        column.sortable ||
                        getTranslation(column.title, t) !==
                          getTranslation("Source", t)
                      }
                      filterable={true}
                    />
                  );
                } else {
                  return column.isShown ? (
                    <Column
                      key={idx}
                      field={column.column}
                      width={column.width}
                      title={getTranslation(column.title, t)}
                      sortable={
                        column.sortable ||
                        getTranslation(column.title, t) !==
                          getTranslation("Source", t)
                      }
                      filterable={true}
                    />
                  ) : null;
                }
              })}
            </Grid>
          </div>
          <div className="buttons d-flex justify-content-start gap-2">
            <button
              className="d-flex justify-content-center align-items-center gap-2 secondary-btn cancel-btn"
              onClick={() => setShowPreviewLeads(false)}
            >
              <p className="m-0">{getTranslation("Cancel", t)}</p>
            </button>
            <button
              className="d-flex justify-content-center align-items-center gap-2 ms-5 primary-btn save-campaign-btn"
              onClick={() => handleSave("save")}
              disabled={selectedLeads[0] === "" || !payload.campaignName }
            >
              <p className="m-0">{getTranslation("Save campaign", t)}</p>
            </button>
            <button
              className="d-flex justify-content-center align-items-center gap-2 ms-5 primary-btn save-up-to-btn"
              onClick={() => handleSave("saveUpTo")}
              disabled={
                !leadsCount ||
                leadsListPage.totalRecords < leadsCount ||
                !Number.isInteger(parseFloat(leadsCount)) || !payload.campaignName
              }
            >
              <p className="m-0">{getTranslation("Save up to", t)}</p>
            </button>
            <Input
              className="k-input-flat leads-input ms-2"
              name="leadsCount"
              value={leadsCount}
              type="number"
              min={1}
              max={leadsListPage.totalRecords}
              onChange={handleChange}
            />
            <label className="d-flex align-items-end">
              {getTranslation("Leads", t)}
            </label>
          </div>
        </div>
      </div>
      {openModal && (
        <InterestRateModalConfirmation
          handleProceedSave={handleSaveClick}
          handleCancelSave={handleCancelSave}
        />
      )}
    </Dialog>
  );
};

export default PreviewLeadsModal;