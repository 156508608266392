/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { getIcon } from "../../utils/iconUtils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CardBody, CardTitle, StackLayout } from "@progress/kendo-react-layout";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import cars from "./sampleCars.js";
import SlideButton from "./SlideButton";
import "../../styles/editProposedDeal/editProposedDeal.css";
import { Input, RadioGroup } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import SavingModal from "../shared/SavingModal";
import FinanceRecord from "../editProposedDeal/FinanceRecord";
import fetchClient from "../../api/fetch";
import noDataImg from "../../assets/images/no_result.svg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { financeRecordData } from "./financeRecordData";
import { format } from "date-fns";
import { formatLogo } from "../shared/brandLogo";
import { fetchImg } from "../../utils/iconUtils";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { checkMarketAu,checkMarketChile, formatCurrency } from "../../utils/helpers";
import Cookie from "../../api/Cookies.js";

//get current market
const checkMarketBrunei = Cookie.get("market") === "brunei";
const checkMarketPanama = Cookie.get("market") === "panama";
const checkMarketCostaRica = Cookie.get("market") === "costa rica";
const checkMarketBulgaria = Cookie.get("market") === "bulgaria";
const checkMarketPoland = Cookie.get("market") === "poland";
const checkMarketCHL = Cookie.get("market") === "chile";

const EditProposedDeal = ({
  marketSettings,
  setLoading,
  setLoadingCount,
  isLoading,
  loadingCount,
  setSuccessModalValues
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const { id } = useParams();
  let location = useLocation();

  const initialInputs = {
    totalKilometers: "",
    tradeInValue: "",
    settlementFigure: ""
  };

  const initialFilters = {
    priceFrom: "",
    priceTo: "",
    monthlyPaymentFrom: "",
    monthlyPaymentTo: ""
  };

  const [carDistribution, setCarDistribution] = useState([]);
  const [selectedCar, setSelectedCar] = useState(undefined);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [showTermError, setShowTermError] = useState(false);
  const [carousel, setCarousel] = useState({
    type: "model",
    typeNum: 1
  });
  const [carouselContent, setCarouselContent] = useState([]);
  const [showBackModal, setShowBackModal] = useState(false);
  const [data, setData] = useState({
    financeGrid: financeRecordData,
    carousel: cars,
    financeType: []
  });
  
  //Type of car
  const [values, setValues] = useState({
    carType: "New",
    financeType: null
  });
  const [payload, setPayload] = useState({});
  const carPerPage = useRef(0);
  const clientName = useRef("");
  const isApply = useRef(false);
  const isInitial = useRef(true);
  const isSave = useRef(false);
  const swiperEl = useRef(undefined);
  const selectedHolder = useRef(null);
  const filterHolder = useRef(initialFilters);
  const isSliderReset = useRef(true);
  const initialProposed = useRef(undefined);

  const typeOfCar = [
    {
      label: getTranslation("New", t),
      value: "New",
      className: "newTypeOfCar",
      disabled: values.carType === "Used" || !values.carType
    },
    {
      label: getTranslation("Used", t),
      value: "Used",
      className: "usedTypeOfCar",
      disabled: values.carType === "New" || !values.carType
    }
  ];

  const setFinanceGridData = (financeRes, data) => {
    let index;
    if (data.type && data.type === "price") {
      index = data.field.indexOf("Formatted");
    }
    const symbol = marketSettings.currencySymbol;

    const getTermKmCondition = (type) =>
      `${financeRes[type].term} / ${
        financeRes[type].totalKilometers
          ? financeRes[type].totalKilometers.toLocaleString() + " km"
          : "0 km"
      }`;

    const getNonTermKmCondition = (type) => {
      return !financeRes[type][data.field]
        ? data.type === "price"
          ? financeRes[type][data.field.slice(0, index)] !== null
            ? symbol + "0"
            : "N/A"
          : [
              "startKilometers",
              "startAge",
              "vin",
              "licencePlate",
              "totalKilometers",
              "fullName"
            ].includes(data.field)
          ? type === "proposed" && data.field === "totalKilometers"
            ? "N/A"
            : null
          : "N/A"
        : data.type
        ? data.type === "date"
          ? format(new Date(financeRes[type][data.field]), "dd/MM/yyyy")
          : data.type === "price"
          ? formatCurrency(financeRes[type][data.field], symbol)
          : financeRes[type][data.field] + "%"
        : typeof financeRes[type][data.field] === "string"
        ? getTranslation(financeRes[type][data.field], t)
        : financeRes[type][data.field];
    };

    //checks which market the field should be
    if (!checkMarketChile() && data.field === "comissionFormatted") return {details: null};

    return data.field === "termOrTotalKm"
      ? {
          ...data,
          proposed: financeRes.proposed ? getTermKmCondition("proposed") : "",
          existing: getTermKmCondition("existing")
        }
      : {
          ...data,
          proposed: financeRes.proposed
            ? getNonTermKmCondition("proposed")
            : "",
          existing: getNonTermKmCondition("existing")
        };
  };

  useEffect(() => {
    fetchFinanceTypes();
  }, []);

  useEffect(() => {
    setLoading(true);
    let URL = `/v1/leads/${id}/proposed/vehicles?level=${carousel.typeNum}${
      carousel.typeNum > 1
        ? "&model=" +
          (selectedCar ? encodeURIComponent(selectedCar.model) : encodeURIComponent(selectedHolder.current?.model)) +
          (carousel.typeNum === 3
            ? "&version=" +
              (encodeURIComponent(selectedCar
                ? selectedCar.version
                : selectedHolder.current?.version))
            : "")
        : ""
    }`;

    if (isApply.current) {
      for (const item in payload.filters) {
        if (payload.filters[item]) {
          URL += `&${item}=${payload.filters[item]}`;
        }
      }
    }

    fetchClient()
      .get(URL)
      .then((res) => {
        if (!isSave.current && !isInitial.current) {
          isSliderReset.current = true;
          swiperEl.current?.slideTo(0);
        }
        const financeRes = res.data.financeRecord;
        if (selectedCar === undefined) {
          clientName.current = res.data.customerRecord.customerName;
          const newData = {
            ...data,
            client: res.data.clientPosition,
            finance: financeRes,
            existing: {
              totalKilometers: financeRes.existing.totalKilometers,
              tradeInValue: financeRes.existing.tradeInValueFormatted,
              settlementFigure: financeRes.existing.settlementValueFormatted
            },
            carousel: res.data.proposedVehicles
          };
          setData(newData);
          setPayload({
            ...payload,
            filters: initialFilters,
            dealCalc: { ...payload.dealCalc, ...initialInputs }
          });
          setSelectedCar(
            res.data.proposedVehicles.length > 0 ? financeRes.proposed : null
          );

          if (isInitial.current) {
            setCarousel({ type: "modelFullName", typeNum: 3 });
            return;
          }
        }
        setCarouselContent(
          isInitial.current
            ? res.data.proposedVehicles.sort(
                (a, b) =>
                  (b.replacementVehicleId ===
                    data.finance.proposed.replacementVehicleId) -
                  (a.replacementVehicleId ===
                    data.finance.proposed.replacementVehicleId)
              )
            : res.data.proposedVehicles
        );
        if (isInitial.current || isSave.current) {
          initialProposed.current = financeRes.proposed;
          handleChangeCar(financeRes.proposed);
        } else {
          handleChangeCar(
            res.data.proposedVehicles.length > 0
              ? carPerPage.current > 2 && res.data.proposedVehicles.length > 2
                ? res.data.proposedVehicles[1]
                : res.data.proposedVehicles[0]
              : null
          );
        }
      })
      .catch((err) => {
        setSelectedCar(null);
        console.log(err);
        isInitial.current = false;
      })
      .finally(() => {
        if (!isInitial.current) {
          setTimeout(() => setLoading(false), 100);
        }
      });
  }, [carousel]);

  //Swiper
  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    const pageNum = Math.min(3, Math.floor(window.innerWidth / 480));
    if (pageNum) {
      carPerPage.current = pageNum;
      const arr = [];
      // store to array 0, 0 + carPerPage, ...
      // previous element becomes start & next element is end
      for (let i = 0; i < Math.ceil(carouselContent.length / pageNum); i++) {
        if (i === 0) {
          arr.push(i);
        } else {
          arr.push(arr[i - 1] + pageNum);
        }
      }

      setCarDistribution(arr);
    }
  }, [currentWidth, carouselContent]);

  useEffect(() => {
    const loaderbg = document.querySelector(".loading.grid");
    if (isApply.current && loadingCount && loaderbg) {
      loaderbg.style.background = "transparent";
    }
  }, [isLoading]);

  useEffect(() => {
    setValues({
      ...values,
      financeType: {
        ...values.financeType,
        textField: t(
          values.financeType?.financeType.toLowerCase().split(/[ -]+/).join("_")
        )
      }
    });
  }, [t]);

  const fetchFinanceTypes = () =>
    fetchClient()
      .get("v1/common/financetypes")
      .then((res) =>
        setData({
          ...data,
          financeType: res.data.data
            .filter((item) => item.value !== 1)
            .map((item) => ({
              ...item,
              textField: getTranslation(item.textField, t)
            }))
        })
      );

  const handleOpenFinanceTypeDropdown = () => fetchFinanceTypes();

  const handleBack = () => {
    if (
      !initialProposed.current ||
      JSON.stringify(data.finance.proposed) ===
        JSON.stringify(initialProposed.current)
    ) {
      Navigate(`/customer-record/${id}`, {
        state: { prevPage: location.state?.prevPage }
      });
    } else {
      setShowBackModal(true);
    }
  };

  const handleSwiperButtons = (swiper) => {
    if (swiper.isBeginning) {
      swiper.navigation.prevEl[0].classList.add("disabled");
      swiper.navigation.nextEl[0].classList.remove("disabled");
    } else if (swiper.isEnd) {
      swiper.navigation.nextEl[0].classList.add("disabled");
      swiper.navigation.prevEl[0].classList.remove("disabled");
    } else {
      swiper.navigation.prevEl[0].classList.remove("disabled");
      swiper.navigation.nextEl[0].classList.remove("disabled");
    }
  };

  const handleDropdownChange = (e) => {
    setValues({ ...values, financeType: e.target.value });
    setPayload({
      ...payload,
      dealCalc: {
        ...payload.dealCalc,
        replacementFinanceType: e.target.value.financeType
      }
    });
  };

  // moving selection forward
  const handleChangeCar = (car) => {
    if (!isInitial.current && selectedCar && selectedCar === car) {
      // move to next level
      switch (carousel.type) {
        case "model":
          setCarouselContent([]);
          setCarousel({ type: "version", typeNum: 2 }); // also save previously selected
          setLoadingCount(0);
          break;
        case "version":
          setCarouselContent([]);
          setCarousel({ type: "modelFullName", typeNum: 3 });
          setLoadingCount(0);
          break;
        default:
          break;
      }
      setPayload({ ...payload, filters: filterHolder.current });
    } else {
      isSliderReset.current = false;
      isInitial.current = false;
      setSelectedCar(car);
      setValues({
        financeType: car
          ? {
              value: car.financeType,
              financeType: car.financeTypeName,
              textField: getTranslation(car.financeTypeName, t)
            }
          : { financeType: "" },
        carType: car ? car.newOrUsed : null
      });
      setShowTermError(false);
      setData((prevData) => ({
        ...prevData,
        finance: {
          ...prevData.finance,
          proposed: car
        },
        financeGrid: prevData.financeGrid.map((element) =>
          setFinanceGridData(
            {
              existing: prevData.finance.existing,
              proposed: car
            },
            element
          )
        )
      }));
      if (car) {
        selectedHolder.current = car;
        setPayload({
          ...payload,
          dealCalc: {
            ...payload.dealCalc,
            ...initialInputs,
            dealerDeposit: car.totalDealerDepositFormatted.toFixed(2),
            additionalCustomerDeposit:
              car.totalCustomerDepositFormatted.toFixed(2),
            replacementFinanceTerm: car.term,
            replacementFinanceType: car.financeTypeName,
            replacementInterestRatePercentage: car.interestRateFormatted,
            newOrUsed: car.newOrUsed,
            purchasePrice: car.purchasePriceFormatted,
            replacementVehicleId: car.replacementVehicleId
          }
        });
      }

      if (isSave.current) {
        setTimeout(
          () =>
            setSuccessModalValues({
              message: getTranslation("Proposed offer saved successfully", t),
              isVisible: true
            }),
          100
        );
      }

      isSave.current = false;
    }
  };

  // moving selection backwards
  const handleCarouselBtnClick = (e) => {
    setCarouselContent([]);
    let typeNum;
    switch (e.target.name) {
      case "model":
        typeNum = 1;
        break;
      case "version":
        typeNum = 2;
        break;
      default:
        typeNum = 3;
        break;
    }
    setCarousel({ type: e.target.name, typeNum });
    setPayload({ ...payload, filters: filterHolder.current });
    setLoadingCount(0);
    setShowTermError(false);
  };

  const handleChangeInput = (e) => {
    if (
      [
        "priceFrom",
        "priceTo",
        "monthlyPaymentFrom",
        "monthlyPaymentTo"
      ].includes(e.target.name) &&
      (Number(e.target.value) ||
        e.target.value === "-" ||
        !e.target.value ||
        e.target.value === "0")
    ) {
      setPayload({
        ...payload,
        filters: {
          ...payload.filters,
          [e.target.name]: e.target.value
        }
      });
    } else if (
      e.target.value >= 0 &&
      (e.target.name === "replacementFinanceTerm"
        ? !(Number(e.target.value) % 1)
        : true) && // disallow decimals
      (["totalKilometers", "tradeInValue", "settlementFigure"].includes(
        e.target.name
      )
        ? e.target.value.split(".").length <= 2 // limits inputted dot to one
        : true) &&
      (e.target.name === "replacementInterestRatePercentage"
        ? e.target.value <= 100 && e.target.value > 0
        : true)
    ) {
      setPayload({
        ...payload,
        dealCalc: {
          ...payload.dealCalc,
          [e.target.name]:
            e.target.name === "replacementFinanceTerm"
              ? e.target.value
                ? Number(e.target.value.replace(".", ""))
                : ""
              : [
                  "totalKilometers",
                  "tradeInValue",
                  "settlementFigure"
                ].includes(e.target.name)
              ? e.target.value
                ? e.target.value.replace("-", "") // disallow negatives
                : ""
              : e.target.value
        }
      });
    }
    let isValidTerm =
  e.target.name === "replacementFinanceTerm" &&
  (
    (e.target.value >= 12 && e.target.value <= 60 && e.target.value % 12 === 0) ||
    Number(e.target.value) === data.financeGrid.find((item) => item.field === "term").proposed
  );

if (values.financeType.value === 9) {
  const financeTypeValues = [72, 84, 96, 108, 120];
  isValidTerm = isValidTerm || financeTypeValues.includes(Number(e.target.value));
}

if ((checkMarketBrunei || checkMarketPanama ) && [72, 84].includes(Number(e.target.value))) {
  isValidTerm = true;
}

if(checkMarketCostaRica && [72, 84, 96].includes(Number(e.target.value)))
  {
    isValidTerm = true;
  }

if(checkMarketBulgaria && [72].includes(Number(e.target.value)))
{
  isValidTerm = true;
}
if(checkMarketPoland && [24,35,47,59,72].includes(Number(e.target.value)))
{
  isValidTerm = true;
}
if(checkMarketCHL && [25, 37, 49].includes(Number(e.target.value)))
{
  isValidTerm = true;
}
if (isValidTerm) {
  setShowTermError(false);
} else if (e.target.name === "replacementFinanceTerm") {
  setShowTermError(true);
} else {
  return;
}

  };

  const handleFocusOut = (e) => {
    if (
      [
        "priceFrom",
        "priceTo",
        "monthlyPaymentFrom",
        "monthlyPaymentTo"
      ].includes(e.target.name)
    ) {
      setPayload({
        ...payload,
        filters: {
          ...payload.filters,
          [e.target.name]:
            payload.filters[e.target.name] &&
            payload.filters[e.target.name] !== "-"
              ? Number(payload.filters[e.target.name]).toFixed(2)
              : ""
        }
      });
    } else {
      setPayload({
        ...payload,
        dealCalc: {
          ...payload.dealCalc,
          [e.target.name]:
            e.target.name === "replacementFinanceTerm"
              ? payload.dealCalc.replacementFinanceTerm
              : !payload.dealCalc[e.target.name]
              ? e.target.name === "additionalCustomerDeposit" ||
                e.target.name === "dealerDeposit"
                ? "0.00"
                : ""
              : payload.dealCalc[e.target.name]
              ? Number(payload.dealCalc[e.target.name]).toFixed(2)
              : ""
        }
      });
    }
  };

  const configDataTypes = () => {
    const nonFloat = [
      "newOrUsed",
      "replacementFinanceType",
      "totalKilometers",
      "replacementVehicleId"
    ];
    let newData = {};
    for (const item in payload.dealCalc) {
      if (payload.dealCalc[item] || payload.dealCalc[item] === 0) {
        newData[item] = nonFloat.includes(item)
          ? payload.dealCalc[item]
          : Number(payload.dealCalc[item]);
      } else {
        newData[item] = null;
      }
    }

    return newData;
  };

  const handleApply = (value = null, field = null) => {
    isApply.current = true;
    const newData = configDataTypes();
    if (
      isApply.current &&
      (filterHolder.current !== payload.filters || !selectedCar)
    ) {
      setLoadingCount(0);
      filterHolder.current = payload.filters;
      setCarousel({
        ...carousel
      });
    } else {
      setLoadingCount(1);
    }
    setLoading(true);
    fetchClient()
      .post(`/v1/leads/${id}/proposed/calculate`, newData)
      .then((res) => {
        const newExisting = {
          ...data.finance.existing,
          equity: res.data.data.equity,
          equityFormatted: res.data.data.equityFormatted,
          tradeInValueFormatted: newData.tradeInValue
            ? newData.tradeInValue
            : data.finance.existing.tradeInValueFormatted,
          settlementValueFormatted: newData.settlementFigure
            ? newData.settlementFigure
            : data.finance.existing.settlementValueFormatted,
          totalKilometers: newData.totalKilometers
        };

        const newProposed = {
          ...data.finance.proposed,
          monthlyPayment: res.data.data.newMonthlyPayment,
          monthlyPaymentFormatted: res.data.data.newMonthlyPaymentFormatted,
          totalDeposit: res.data.data.totalDeposit,
          totalDepositFormatted: res.data.data.totalDepositFormatted,
          monthlyDifference: res.data.data.monthlyDifference,
          monthlyDifferenceFormatted: res.data.data.monthlyDifferenceFormatted,
          cashback: res.data.data.depositToMatchThePayment,
          cashbackFormatted: res.data.data.depositToMatchThePaymentFormatted,
          gfv: res.data.data.gfv,
          gfvFormatted: res.data.data.gfvFormatted,
          totalAmountFinanced: res.data.data.totalAmountFinanced,
          totalAmountFinancedFormatted:
            res.data.data.toTalAmountFinancedFormatted,
          financeTypeName: newData.replacementFinanceType,
          term: newData.replacementFinanceTerm,
          totalCustomerDeposit: res.data.data.totalCustomerDeposit,
          totalCustomerDepositFormatted: newData.additionalCustomerDeposit,
          totalDealerDeposit: res.data.data.totalDealerDeposit,
          totalDealerDepositFormatted: newData.dealerDeposit,
          interestRateFormatted: res.data.data.replacementInterestRateFormatted,
        };

        if (value !== null && field) {
          switch (field) {
            case "purchasePrice":
              newProposed.purchasePriceFormatted = value;
              newProposed.purchasePrice = value;
              break;
            case "interestRate":
              newProposed.interestRateFormatted = value;
              newProposed.interestRate = value;
              break;
            case "replacementInterestRate":
              newProposed.replacementInterestRatePercentage = value;
              newProposed.replacementInterestRate = value;
              break;
            default:
              break;
          }
        }

        setData({
          ...data,
          finance: {
            ...data.finance,
            existing: newExisting,
            proposed: newProposed
          },
          financeGrid: data.financeGrid.map((element) =>
            setFinanceGridData(
              {
                existing: newExisting,
                proposed: newProposed
              },
              element
            )
          )
        });

        setTimeout(
          () =>
            setSuccessModalValues({
              message: getTranslation("Changes applied successfully", t),
              isVisible: true
            }),
          50
        );
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = () => {
    setLoadingCount(1);
    setLoading(true);
    isApply.current = false;
    const calcPayload = configDataTypes();
    fetchClient()
      .post(`/v1/leads/${id}/proposed/calculate`, calcPayload)
      .then((res) => {
        const src = res.data.data;
        let newData = {
          ...calcPayload,
          equity: src.equity,
          totalDeposit: src.totalDeposit,
          totalAmountFinanced: src.totalAmountFinanced,
          gfv: src.gfv,
          newMonthlyPayment: src.newMonthlyPayment,
          monthlyDifference: src.monthlyDifference,
          costOfChange: src.depositToMatchThePayment,
          replacementInterestRatePercentage: src.replacementInterestRateFormatted
        };
        newData.term = newData.replacementFinanceTerm;
        newData.financeType = newData.replacementFinanceType;
        newData.additionalDealerDeposit = newData.dealerDeposit;
        newData.settlementValue = newData.settlementFigure;
        delete newData.replacementFinanceTerm;
        delete newData.replacementFinanceType;
        delete newData.dealerDeposit;
        delete newData.settlementFigure;

        const auData = {
          ...newData,
          upfrontFees: src.upfrontFees,
          comparisonRepayment: src.comparisonRepayment,
          comparisonRate: src.comparisonRate,
          loanAmountForComparisonRate: src.loanAmountForComparisonRate,
          weeklyRepayment: src.weeklyRepayment,
          fortnightlyRepayment: src.fortnightlyRepayment
        };

        fetchClient()
          .put(`/v1/leads/${id}/proposed`, checkMarketAu() ? auData : newData)
          .then((res) => {
            isSave.current = true;
            setSelectedCar(undefined);
            setCarousel({ ...carousel });
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

//function to get the term error message
const getTermErrorMsg = (value) => {
  let msg = getTranslation("allowed terms are ", t);
  let terms = [12, 24, 36, 48, 60];

  if(checkMarketBrunei || checkMarketPanama)
  {
    // If market is true, add values 72 and 84 to the terms array
    terms.push(72, 84);
  }

  if(checkMarketCostaRica)
    {
      terms.push(72, 84, 96);
    }
  
  if(checkMarketBulgaria)
  {
    terms.push(72);
  }
  if(checkMarketPoland)
  {
    terms = [24,35,47,59,72];
  }
  if(checkMarketCHL)
  {
    terms = [25,37,49];
  }
  if (value) {
    if (Number(value) === Number(data.finance.proposed.term))
      terms.push(value);
    if (values.financeType.value === 9) terms.push(96,108,120);
    const finTerms = terms.sort((a,b) => a-b);
    finTerms.forEach((value, idx) => {
      if (idx === finTerms.length - 1)
        msg += getTranslation("and", t) + " " + value;
      else msg += value + ", ";
    });

    return msg;
  }

  return "";
};

  return (
    <div className="edit-deal">
      <div className="d-flex gap-5 align-items-center mb-4 header">
        <img
          onClick={handleBack}
          src={getIcon("arrow-back.svg")}
          alt="back icon"
          className="back-btn"
        />
        <p className="h1 mb-0">
          {clientName.current ? clientName.current : "-"}
        </p>
      </div>

      <CardBody>
        <div className="edit-deal-btn">
          <button
            name="model"
            onClick={
              carousel.type === "model" ? undefined : handleCarouselBtnClick
            }
            className="primary-btn"
          >
            {getTranslation("Model", t)}
          </button>
          <button
            name="version"
            onClick={
              carousel.type === "version" ? undefined : handleCarouselBtnClick
            }
            className={
              carousel.type === "model" ? "secondary-btn" : "primary-btn"
            }
          >
            {getTranslation("Version", t)}
          </button>
          <button
            disabled={carousel.type === "model"}
            className={`btn ${
              carousel.type === "modelFullName"
                ? "primary-btn"
                : "secondary-btn"
            }`}
          >
            {getTranslation("Model Full Name", t)}
          </button>
        </div>

        <div className="swiper-cont mb-3">
          <Swiper
            modules={[Navigation, Pagination]}
            navigation={{
              nextEl: ".slider-btn-next",
              prevEl: ".slider-btn-prev"
            }}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{
              clickable: true,
              type: "bullets"
            }}
            onInit={(swiper) => {
              swiperEl.current = swiper;
              swiper.navigation.prevEl[0].classList.add("disabled");
            }}
            onSlideChange={(swiper) => {
              handleSwiperButtons(swiper);
              if (!isSliderReset.current) {
                let array = [...carouselContent];
                array = array.splice(
                  swiper.activeIndex * carPerPage.current,
                  carPerPage.current
                );
                setTimeout(() => {
                  handleChangeCar(
                    carPerPage.current > 2 && array.length > 2
                      ? array[1]
                      : array[0]
                  );
                }, 50);
              }
            }}
          >
            {selectedCar !== null ? (
              carDistribution.map((current, idx, outArr) => {
                return (
                  <SwiperSlide key={idx}>
                    {(idx !== carDistribution.length
                      ? carouselContent.slice(current, outArr[idx + 1])
                      : carouselContent.slice(current)
                    ).map((car, index, inArr) => {
                      let imageSizeClass;
                      const vehicleIds = inArr.map(
                        (car) => car.replacementVehicleId
                      );
                      const imageUrl = `${car.brand?.toLowerCase()}/${formatLogo(
                        car.brand + " " + car.model,
                        "jpg"
                      )}`;
                      if (
                        selectedCar?.replacementVehicleId ===
                        car?.replacementVehicleId
                      ) {
                        imageSizeClass = "img-lg";
                      } else {
                        if (
                          vehicleIds.includes(selectedCar?.replacementVehicleId)
                        ) {
                          const carIndex = vehicleIds.indexOf(
                            selectedCar?.replacementVehicleId
                          );
                          switch (true) {
                            case index === 0 && carIndex === 2:
                            case index === 2 && carIndex === 0:
                              imageSizeClass = "img-sm";
                              break;
                            default:
                              imageSizeClass = "img-md";
                              break;
                          }
                        } else {
                          imageSizeClass = "img-md";
                        }
                      }
                      return (
                        <div key={index} className="car-card mb-2 text-center">
                          <div className="car-info">
                            <div className="orig-price">
                              {getTranslation("From", t)}{" "}
                              <b className="price-styles">
                                {formatCurrency(
                                  selectedCar?.replacementVehicleId ===
                                    car?.replacementVehicleId
                                    ? data.finance.proposed
                                        ?.monthlyPaymentFormatted
                                    : car?.monthlyPaymentFormatted,
                                  marketSettings.currencySymbol
                                )}
                              </b>{" "}
                              {getTranslation("per month", t)}
                            </div>
                            {(selectedCar?.replacementVehicleId ===
                            car?.replacementVehicleId
                              ? data.finance.proposed?.cashbackFormatted
                              : car?.cashbackFormatted) > 0 && (
                              <div className="match-pay">
                                <b className="price-styles">
                                  {formatCurrency(
                                    selectedCar?.replacementVehicleId ===
                                      car?.replacementVehicleId
                                      ? data.finance.proposed?.cashbackFormatted
                                      : car?.cashbackFormatted,
                                    marketSettings.currencySymbol
                                  )}
                                </b>{" "}
                                {getTranslation("to match payment", t)}
                              </div>
                            )}
                          </div>
                          <span className={imageSizeClass}>
                            <img
                              draggable={false}
                              onClick={() => handleChangeCar(car)}
                              onError={(e) => {
                                e.target.src = fetchImg("placeholder.png");
                              }}
                              src={fetchImg(imageUrl)}
                              alt={`${car.title} icon`}
                            />
                          </span>
                          <div
                            className={
                              selectedCar?.replacementVehicleId ===
                              car?.replacementVehicleId
                                ? "car-model selected p-0"
                                : "car-model p-0"
                            }
                          >
                            {car.title}
                          </div>
                        </div>
                      );
                    })}
                  </SwiperSlide>
                );
              })
            ) : (
              <SwiperSlide>
                <div className="car-card mb-2 text-center">
                  <span>
                    <img
                      className="no-data"
                      src={noDataImg}
                      alt="no data icon"
                    />
                  </span>
                  <div>
                    <h4>{getTranslation("Sorry! No result found", t)}</h4>
                    <p>
                      {getTranslation(
                        "The data is not currently available. Please try again at any time later",
                        t
                      )}
                      .
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            )}
          </Swiper>
          <SlideButton mode="prev" />
          <SlideButton mode="next" />
        </div>
      </CardBody>

      <StackLayout className="row m-0">
        <div className="col-lg-6 p-0 pe-lg-3">
          <CardBody className="finance-record">
            <CardTitle>{getTranslation("Finance Record", t)}</CardTitle>
            <FinanceRecord
              payload={payload}
              selectedCar={selectedCar}
              handleSave={handleApply}
              handleChange={handleChangeInput}
              setPayload={setPayload}
              currencySymbol={marketSettings.currencySymbol}
              data={data}
              formatCurrency={formatCurrency}
            />
          </CardBody>
        </div>
        <div className="col-lg-6 p-0 ps-lg-3">
          <CardBody className="deal-calculator">
            <CardTitle>{getTranslation("Deal calculator", t)}</CardTitle>

            <div className="calculations">
              {!checkMarketAu() && (
                <div className="d-flex flex-wrap gap-1 align-items-center mb-4">
                  <label className="label">
                    {getTranslation("Mileage", t)}
                  </label>
                  <div className="d-flex align-items-center">
                    <Input
                      value={
                        data.existing?.totalKilometers
                          ? data.existing.totalKilometers.toFixed(2)
                          : "N/A"
                      }
                      readOnly={true}
                      placeholder="Calculated"
                    />{" "}
                    -{" "}
                    <Input
                      name="totalKilometers"
                      disabled={!selectedCar}
                      value={payload.dealCalc?.totalKilometers}
                      onChange={handleChangeInput}
                      onBlur={handleFocusOut}
                      placeholder={getTranslation("Override", t)}
                    />
                  </div>
                </div>
              )}
              {!checkMarketAu() && (
                <div className="d-flex flex-wrap gap-1 align-items-center mb-4">
                  <label className="label">
                    {getTranslation("Part exchange", t)}
                  </label>
                  <div className="d-flex align-items-center">
                    <Input
                      value={
                        data.existing?.tradeInValue
                          ? data.existing.tradeInValue.toFixed(2)
                          : "N/A"
                      }
                      readOnly={true}
                      placeholder="Calculated"
                    />{" "}
                    -{" "}
                    <Input
                      name="tradeInValue"
                      disabled={!selectedCar}
                      value={payload.dealCalc?.tradeInValue}
                      onChange={handleChangeInput}
                      onBlur={handleFocusOut}
                      placeholder={getTranslation("Override", t)}
                    />
                  </div>
                </div>
              )}
              <div className="d-flex flex-wrap gap-1 align-items-center mb-4">
                <label className="label">
                  {getTranslation("Settlement figure", t)}
                </label>
                <div className="d-flex align-items-center">
                  <Input
                    value={
                      data.existing?.settlementFigure
                        ? data.existing.settlementFigure.toFixed(2)
                        : "N/A"
                    }
                    readOnly={true}
                    placeholder="Calculated"
                  />{" "}
                  -{" "}
                  <Input
                    name="settlementFigure"
                    disabled={!selectedCar}
                    value={payload.dealCalc?.settlementFigure}
                    onChange={handleChangeInput}
                    onBlur={handleFocusOut}
                    placeholder={getTranslation("Override", t)}
                  />
                </div>
              </div>
            </div>

            <div className="type-of-car">
              <CardTitle className="deal-calc-lbl">
                {getTranslation("Type of car", t)}
              </CardTitle>
              <RadioGroup
                data={typeOfCar}
                layout={"horizontal"}
                value={values.carType}
              />
            </div>

            <div className="price">
              <CardTitle className="deal-calc-lbl">
                {getTranslation("Price", t)}
              </CardTitle>
              <div className="input-container">
                <div className="d-flex flex-column">
                  <div className="label">{getTranslation("From", t)}</div>
                  <Input
                    name="priceFrom"
                    value={payload.filters?.priceFrom}
                    onChange={handleChangeInput}
                    onBlur={handleFocusOut}
                    className="primary-input"
                  />
                </div>
                <div className="d-flex flex-column">
                  <div className="label">{getTranslation("To", t)}</div>
                  <Input
                    name="priceTo"
                    value={payload.filters?.priceTo}
                    onChange={handleChangeInput}
                    onBlur={handleFocusOut}
                    className="primary-input"
                  />
                </div>
              </div>
            </div>

            <div className="monthly-pay-range">
              <CardTitle className="deal-calc-lbl">
                {getTranslation("Monthly payment range", t)}
              </CardTitle>
              <div className="input-container">
                <div className="d-flex flex-column">
                  <div className="label">{getTranslation("From", t)}</div>
                  <Input
                    name="monthlyPaymentFrom"
                    value={payload.filters?.monthlyPaymentFrom}
                    onChange={handleChangeInput}
                    onBlur={handleFocusOut}
                    className="primary-input"
                  />
                </div>
                <div className="d-flex flex-column">
                  <div className="label">{getTranslation("To", t)}</div>
                  <Input
                    name="monthlyPaymentTo"
                    value={payload.filters?.monthlyPaymentTo}
                    onChange={handleChangeInput}
                    onBlur={handleFocusOut}
                    className="primary-input"
                  />
                </div>
              </div>
            </div>

            <div className="campaign">
              <CardTitle className="deal-calc-lbl">
                {getTranslation("Campaign name", t)}
              </CardTitle>
              <label>
                {data.client?.campaignName
                  ? data.client.campaignName
                  : getTranslation("No campaign assigned", t)}
              </label>
            </div>

            <div className="finance-type">
              <CardTitle className="deal-calc-lbl">
                {getTranslation("Finance type", t)}
              </CardTitle>
              <DropDownList
                data={data.financeType}
                value={values.financeType}
                onOpen={handleOpenFinanceTypeDropdown}
                textField="textField"
                disabled={!selectedCar}
                onChange={handleDropdownChange}
              />
            </div>

            <div className="term">
              <CardTitle className="deal-calc-lbl">
                {getTranslation("Term", t)}
              </CardTitle>
              <Input
                value={payload.dealCalc?.replacementFinanceTerm}
                onChange={handleChangeInput}
                onBlur={handleFocusOut}
                disabled={!selectedCar}
                name="replacementFinanceTerm"
                className="term-input primary-input"
              />
              {showTermError && (
                <div className="term-error">
                  {getTermErrorMsg(payload.dealCalc.replacementFinanceTerm)}
                </div>
              )}
            </div>

            <div className="customer-deposit">
              <div className="label">
                {getTranslation("Customer deposit (Cash)", t)}
              </div>
              <Input
                name="additionalCustomerDeposit"
                onChange={handleChangeInput}
                type="number"
                onBlur={handleFocusOut}
                disabled={!selectedCar}
                value={payload.dealCalc?.additionalCustomerDeposit}
                className="term-input primary-input"
              />
            </div>

            <div className="add-dealer-deposit">
              <div className="label">
                {getTranslation("Additional dealer deposit", t)}
              </div>
              <Input
                name="dealerDeposit"
                onChange={handleChangeInput}
                type="number"
                onBlur={handleFocusOut}
                disabled={!selectedCar}
                value={payload.dealCalc?.dealerDeposit}
                className="term-input primary-input"
              />
            </div>

            <div className="deal-calc-btn">
              <button
                onClick={handleApply}
                disabled={showTermError}
                className="btn apply-btn secondary-btn"
              >
                {getTranslation("Apply", t)}
              </button>
              <button
                onClick={handleSave}
                disabled={
                  showTermError ||
                  !selectedCar ||
                  data.finance.proposed.monthlyPaymentFormatted < 0
                }
                className="btn save-btn primary-btn"
              >
                {getTranslation("Save", t)}
              </button>
            </div>
          </CardBody>
        </div>
      </StackLayout>

      {showBackModal && (
        <SavingModal
          message={getTranslation("Do you want to leave without saving?", t)}
          onclickHandleNo={() => setShowBackModal(false)}
          onclickHandleYes={() =>
            Navigate(`/customer-record/${id}`, {
              state: { prevPage: location.state?.prevPage }
            })
          }
        />
      )}
    </div>
  );
};

export default EditProposedDeal;
